/** @jsx jsx */
import { jsx } from 'theme-ui'
import CartPage from '~/components/Cart/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import { Container } from '@theme-ui/components'

const Cart = () => {
  const translate = useTranslate()

  return (
    <Layout>
      <Metadata title={translate('cart.page_title')} />

      <Container
        sx={{
          paddingTop: [4, 4],
          overflow: 'initial'
        }}
      >
        <CartPage />
      </Container>
    </Layout>
  )
}

export default Cart
