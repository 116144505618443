/** @jsx jsx */
import {
  logSentryError,
  selectors,
  useAnalytics,
  useCart,
  useTranslate,
  utils
} from '@chordcommerce/gatsby-theme-performance'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Grid, Text, jsx, Spinner } from 'theme-ui'
import LineItems from '~/components/Cart/LineItems'
import CartSummary from '~/components/Cart/Summary'
import formatMoney from '~/utils/formatMoney'

const CartLoading = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: ['10rem 1.25rem', '12rem 1.25rem'],
        marginRight: [null, '1rem'],
        marginBottom: ['1rem', null]
      }}
    >
      <Spinner aria-label="Loading..." size="50" />
    </Box>
  )
}

const CartEmpty = () => {
  const translate = useTranslate()

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: ['10rem 1.25rem', '12rem 1.25rem'],
        marginRight: [null, '1rem'],
        marginBottom: ['1rem', null]
      }}
    >
      <Text
        variant="h2"
        sx={{
          textTransform: 'uppercase',
          fontSize: ['2.4rem !important', '3rem !important']
        }}
        mb="4"
        mt="0.5rem"
      >
        {translate('cart.empty')}
      </Text>

      <Button as={Link} to="/all-bikes">
        {translate('cart.shop_button')}
      </Button>
    </Box>
  )
}

const CartNotEmpty = ({
  lineItems,
  displayTotal,
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  promotions,
  eligibleForFreeShipping,
  amountNeededForFreeShipping
}) => {
  return (
    <Grid
      columns={[1, null, '1fr 40%', '1fr min(40%, 46rem)']}
      sx={{ gap: [4, null, null, 7], marginBottom: 5 }}
    >
      <LineItems
        items={lineItems}
        amountNeededForFreeShipping={amountNeededForFreeShipping}
        eligibleForFreeShipping={eligibleForFreeShipping}
      />

      <CartSummary
        displayItemTotal={displayItemTotal}
        displayTaxTotal={displayTaxTotal}
        displayShipTotal={displayShipTotal}
        displayTotal={displayTotal}
        promotions={promotions}
        eligibleForFreeShipping={eligibleForFreeShipping}
      />
    </Grid>
  )
}

const CartPage = () => {
  const { cart, loadCart } = useCart()
  const { trackCartViewed } = useAnalytics()
  const { getAmountNeededForFreeShipping } = selectors
  const { getAllCartPromotionsForDisplay } = utils

  useEffect(() => {
    const loadAndTrackCart = async () => {
      try {
        await loadCart()
        trackCartViewed()
      } catch (error) {
        logSentryError(error, { source: 'Cart' })
      }
    }

    loadAndTrackCart()
  }, [loadCart, trackCartViewed])

  const amountNeededForFreeShipping = useSelector(
    getAmountNeededForFreeShipping
  )
  const eligibleForFreeShipping = amountNeededForFreeShipping === 0
  const { isFetching, data: order } = cart

  if (isFetching && order.lineItems && order.lineItems.length === 0)
    return <CartLoading />
  if (!order.lineItems || order.lineItems.length === 0) return <CartEmpty />

  const displayItemTotal = formatMoney(order.lineItemsSubtotalPrice.amount)
  const displayTaxTotal = formatMoney(order.totalTax)
  const displayShipTotal = formatMoney('0.00')
  const displayTotal = formatMoney(order.totalPrice)
  const { lineItems } = order

  const promotions = getAllCartPromotionsForDisplay(order)

  return (
    <CartNotEmpty
      lineItems={lineItems}
      displayTotal={displayTotal}
      displayItemTotal={displayItemTotal}
      displayTaxTotal={displayTaxTotal}
      displayShipTotal={displayShipTotal}
      promotions={promotions}
      amountNeededForFreeShipping={amountNeededForFreeShipping}
      eligibleForFreeShipping={eligibleForFreeShipping}
    />
  )
}

CartNotEmpty.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
  displayTotal: PropTypes.string,
  displayItemTotal: PropTypes.string,
  displayTaxTotal: PropTypes.string,
  displayShipTotal: PropTypes.string,
  promotions: PropTypes.arrayOf(PropTypes.shape({}))
}

export default CartPage
